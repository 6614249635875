import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.paybefore.com"
      }}>{`Paybefore`}</a>{` selects PayPerks as this month's company to watch in their June 2011 issue. PayPerks is honored to be among the prestigious group of previously featured companies such as `}<a parentName="p" {...{
        "href": "http://www.txvia.com"
      }}>{`TxVia`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.mangomoney.com/"
      }}>{`Mango`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.accountnow.com/"
      }}>{`AccountNow`}</a>{`. We hope to be able to follow in their footsteps.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "24.333333333333332%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABkUlEQVQY02N4ZGBp8VzPvOWxgaXb3exCBgZubgYGKUmG1RZ2DK/c/RluO3szfAyIYHjsFchw29mL4a6LD8NDBw8IdvRkYGJAA+88g/59jE7+/8Ev7P/N8NgYBgYGJQYJccGDHn4+TzwDVG65eKvecfMNvu7up3/PxUf+jquP7l1HD/EPDh4MN72DGA/4hzLsDIti0DXQZ9DR0WFg+Fxa/f/P798ffnf2/z+cnHGdgZOzik9MLGu9jVPuK3f/4vtuvg2P3fzK7zl7J96xd0+94+pTed/Nz/+4my/D3uY2hkO+wQz7wmMYdA0MIAa+8w7+/zEh/f+XlOz/Rz39mxgE+HTl5OX95lg7aK1zdNNe5eyh227nKLfdzkX/spuf7EXfEM35Tu7KlR7eXJfDYvgO+Qbz7guP5dE1MODV0dHhYHhmYhP73NBq90tDq1wGBgYuSxtrPQdbW2cNc3MjOUMDPTVTE1NVUxNjF2trrSNhMVZ7ImJtjczNjfrdvc2PR8RZ7AmKMNsQFG6io69voaOjowQA6euJhmMn6oYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/49b3b344c7cc60b32279cd5efb55ea9b/c85cb/paybefore_logo_small1.webp 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/49b3b344c7cc60b32279cd5efb55ea9b/5a46d/paybefore_logo_small1.png 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/49b3b344c7cc60b32279cd5efb55ea9b/5a46d/paybefore_logo_small1.png",
            "alt": "paybefore logo small1",
            "title": "paybefore logo small1",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`The article, see below, provides a nice overview of what PayPerks does and tells some of the story about how PayPerks came to life. After reading the article, my mom exclaimed: "Now, I finally understand what you do!" (Hi, mom!)`}</p>
    <p>{`In addition to thanking Paybefore for featuring us, we want to thank our pilot partners `}<a parentName="p" {...{
        "href": "http://www.comdata.com/"
      }}>{`Comdata`}</a>{` and `}<a parentName="p" {...{
        "href": "http://www.mastercard.com/"
      }}>{`MasterCard`}</a>{`. MasterCard has been an early and strong supporter of PayPerks, providing everything from research to financial support for our pilot and we are proud to be one of MasterCard's `}<a parentName="p" {...{
        "href": "https://www.partnersinprepaid.com/topics/articles/educational-rewards-program-for-the-unbanked.html"
      }}>{`Partners in Prepaid`}</a>{`.`}</p>
    <p>{`You can view the article below or download it by clicking on the download button at the bottom of this viewer.`}</p>
    <iframe className="scribd_iframe_embed" src="//www.scribd.com/embeds/58091797/content?start_page=1&#038;view_mode=scroll&#038;access_key=key-18ez8uudvig0ifeetomt&#038;show_recommendations=true" data-auto-height="false" data-aspect-ratio="0.772727272727273" scrolling="no" id="doc_38001" width="100%" height="600" frameBorder="0"></iframe>
    <p><a parentName="p" {...{
        "href": "http://www.scribd.com/doc/58091797/PayPerks-is-Paybefore-s-Company-to-Watch-in-June-2011"
      }}>{`PayPerks is Paybefore's Company to Watch in June 2011`}</a>{` by `}<a parentName="p" {...{
        "href": "http://www.scribd.com/payperks"
      }}>{`PayPerks Inc`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      